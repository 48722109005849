<template>
  <div>
    <BaseNav></BaseNav>

    <!-- Page content -->
    <div class="page-content">
      <!-- Main content -->
      <div class="content-wrapper">
        <!-- Content area -->
        <div class="content d-flex justify-content-center align-items-center">
          <!-- Registration form -->
          <div class="t-register">
          <div class="t-register__container">
          <form ref="registerForm" id="registerForm" class=" "  @submit.prevent="submitRegister"> 
            <h3 class="t-login__title">Sign up </h3>
            <div class="row">
              <div class=" col-sm-6 col-12 form-group field-registerForm-email required has-success">
              <label class="control-label" for="registerForm-email">First Name</label>
              <input type="text" v-model="registerForm.first_name" id="registerForm-email" class="form-control" name="registerForm[first_name]" placeholder="First Name" aria-required="true" aria-invalid="false">
              </div>
              <div class=" col-sm-6 col-12 form-group field-registerForm-email required has-success">
              <label class="control-label" for="registerForm-email">Last Name</label>
              <input type="text" v-model="registerForm.last_name" id="registerForm-email" class="form-control" name="registerForm[last_name]" placeholder="Last Name" aria-required="true" aria-invalid="false">
              </div>
              <div class=" col-sm-6 col-12 form-group field-registerForm-email required has-success">
              <label class="control-label" for="registerForm-email">Phone Number</label>
              <input type="text" v-model="registerForm.phone" id="registerForm-email" class="form-control" name="registerForm[phone]" placeholder="Phone Number" aria-required="true" aria-invalid="false">
              </div>
              <div class=" col-sm-6 col-12 form-group field-registerForm-email required has-success">
              <label class="control-label" for="registerForm-email">Email</label>
              <input type="email" v-model="registerForm.email" id="registerForm-email" class="form-control" name="registerForm[email]" placeholder="name@youradress.com" aria-required="true" aria-invalid="false">
              </div>
              <div class=" col-sm-12 col-12 form-group field-registerForm-email required has-success">
              <label class="control-label" for="registerForm-email">Country</label>
              <v-select 
                  label="name" 
                  v-model="registerForm.country" 
                  placeholder="Start typing to search" 
                  :options="countryNames"
                  :reduce="country => country" 
                  >
              </v-select>
              </div>
              <div class="col-sm-6 col-12 form-group field-registerForm-password required has-success">
              <label class="control-label" for="registerForm-password">Password</label>
              <input type="password" v-model="registerForm.password" id="registerForm-password" class="form-control" name="registerForm[password]" placeholder="Your password" aria-required="true" aria-invalid="false">
              </div> 
              <div class="col-sm-6 col-12 form-group field-registerForm-password required has-success">
              <label class="control-label" for="registerForm-password">Repeat Password</label>
              <input type="password" v-model="registerForm.password_confirmation" id="registerForm-password" class="form-control" name="registerForm[password]" placeholder="Repeat your password" aria-required="true" aria-invalid="false">
              </div> 
            </div>
            <!-- <div class="t-login__row">
              <input type="hidden" name="registerForm[rememberMe]" value="0"><label><input type="checkbox" id="registerForm-rememberme" name="LoginForm[rememberMe]" value="1" checked=""> <span class="remember-me-text">Keep me logged in</span></label> <router-link :to="{ name: 'ForgotPassword' }" class="t-login__forgot" >Forgot Password ?</router-link> 
            </div> -->
            <div class="t-login__row d-flex align-items-center justify-content-center ">
              <base-captcha></base-captcha>

              <div class="form-group d-flex align-items-center">
                <div class="g-recaptcha" data-sitekey="your_site_key"></div>
              </div>
              
            </div>
            <div>
              <button
              :disabled="registerForm.busy"
               type="submit" class="t-signup__btn" >{{registerForm.busy ? 'Loading..': 'Create Account'}}</button> 
            </div>
         </form> 
         <div class="t-login__or">or</div>
         <div>
            <router-link
                    :to="{ name: 'Login' }" type="submit" class="t-login__btn" >Sign in</router-link> 
          </div>

          </div>
          </div>
          <!-- <form
            class="flex-fill"
            ref="registerForm"
            @submit.prevent="submitRegister"
          >
            <div class="row">
              <div class="col-lg-6 offset-lg-3">
                <div class="card mb-0 shadow rounded">
                  <div class="card-body">
                    <div class="text-center mb-3">
                      <i
                        class="icon-plus3 icon-2x text-success border-success border-3 rounded-round p-3 mb-3 mt-1"
                      ></i>
                      <h5 class="mb-0">Create an Account</h5>
                      <span class="d-block text-muted"
                        >All fields are required</span
                      >
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div
                          class="form-group form-group-feedback form-group-feedback-right"
                        >
                          <input
                            v-model="registerForm.first_name"
                            required
                            name="first_name"
                            type="text"
                            class="form-control"
                            placeholder="First name"
                          />
                          <div class="form-control-feedback">
                            <i class="icon-user-check text-muted"></i>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div
                          class="form-group form-group-feedback form-group-feedback-right"
                        >
                          <input
                            v-model="registerForm.last_name"
                            name="last_name"
                            required
                            type="text"
                            class="form-control"
                            placeholder="Second name"
                          />
                          <div class="form-control-feedback">
                            <i class="icon-user-check text-muted"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="form-group form-group-feedback form-group-feedback-right"
                    >
                      <input
                        v-model="registerForm.email"
                        name="email"
                        required
                        type="email"
                        class="form-control"
                        placeholder="Email Address"
                      />
                      <div class="form-control-feedback">
                        <i class="icon-mention text-muted"></i>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-6">
                        <div
                          class="form-group form-group-feedback form-group-feedback-right"
                        >
                          <v-select 
                              label="name" 
                              v-model="registerForm.country" 
                              placeholder="Start typing to search" 
                              :options="countryNames"
                              :reduce="country => country" 
                              >
                          </v-select>

                         
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div
                          class="form-group form-group-feedback form-group-feedback-right"
                        >
                          <input
                            v-model="registerForm.phone"
                            name="phone"
                            required
                            type="tel"
                            class="form-control"
                            placeholder="Your Phone"
                          />
                          <div class="form-control-feedback">
                            <i class="icon-phone text-muted"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div
                          class="form-group form-group-feedback form-group-feedback-right"
                        >
                          <input
                            v-model="registerForm.password"
                            name="password"
                            type="password"
                            required
                            class="form-control"
                            placeholder="Create password"
                          />
                          <div class="form-control-feedback">
                            <i class="icon-user-lock text-muted"></i>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div
                          class="form-group form-group-feedback form-group-feedback-right"
                        >
                          <input
                            v-model="registerForm.password_confirmation"
                            name="password_confirmation"
                            required
                            type="password"
                            class="form-control"
                            placeholder="Repeat password"
                          />
                          <div class="form-control-feedback">
                            <i class="icon-user-lock text-muted"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <base-captcha></base-captcha>

                    <div class="form-group">
                      

                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-input-styled"
                            data-fouc
                          />
                          Accept <a href="#">terms of service</a>
                        </label>
                      </div>
                    </div>

                    <button
                      :disabled="registerForm.busy"
                      type="submit"
                      class="btn btn-primary btn-labeled btn-labeled-right"
                    >
                      <b><i class="icon-plus3"></i></b> {{registerForm.busy ? 'Loading..': 'Create Account'}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form> -->
          <!-- /registration form -->
        </div>
        <!-- /content area -->
      </div>
      <!-- /main content -->
    </div>
    <!-- /page content -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {countries} from "countries-list";
export default {
  data() {
    return {
      registerForm: new this.$Form({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country: "",
        password: "",
        token: "",
      }),
    };
  },

  computed: {
    ...mapState("auth", ["loginForm"]),
    countryNames() {
      return Object.keys(countries).map((country) => countries[country].name)
    }
  },


  methods: {
    ...mapActions("auth", ["doRegister", "doLogin"]),
    submitRegister() {
      let token
      if (process.env === "production") {
        token = window.grecaptcha.getResponse();
      } else {
        token = "fasdflk";
      }
      if (!token || token == "") {
        this.$notify({
              title: "Error",
              text: "Please verify you are not a robot",
              style: "danger",
            });
      } else {
        this.doRegister.token = token
        this.doRegister(this.registerForm)
          .then((res) => {
            console.log("res.data", res.data);
            this.$notify({
              title: "Success",
              text: "Registration successiful!",
            });
            // login
            this.doLogin(this.registerForm)
              .then((res) => {
                console.log(res.data.Data);
                document.location.href = `/${process.env.VUE_APP_INSTALL_DIR}/home/`
              })
              .catch((err) => {
                this.$notify({
                  title: "Error",
                  text: err.response.data ? err.response.data.Message : "Error",
                  style: "danger",
                });
              });
          })
          .catch((err) => {
            this.$notify({
              title: "Error",
              text: err.response.data ? err.response.data.Message : "Error",
              style: "danger",
            });
          });
        }
    },
  },
};
</script>
